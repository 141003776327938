import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage4() {
  const question = {
    title: 'Frage 4 von 35',
    headline: '',
    subline:
      'Betreust du lieber Patienten mit akuten oder chronischen Erkrankungen?',
  }

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-turkis-waves">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="turkis"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="range"
              color="yellow"
              from="Patienten mit akuten Erkrankungen"
              to="Patienten mit chronischen Erkrankungen"
              index={4}
            />
          </div>
        </section>

        <MatchingNavigation
          color="turkis"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-5`}
          prev={`${AREA_CARE_PATH}/spielen/frage-3`}
          index={4}
        />

        <Icon position="4" name="doctor" />
      </main>
    </>
  )
}
